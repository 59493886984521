import styled from 'styled-components'
import { color, fontSize } from '../../../data/dataPatterns'
// Link
import { Link } from 'gatsby'

export const Container = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 100px 8% 200px;

  .infos {
    width: 40%;
    margin-left: 100px;
    h1 {
      font-family: Marta, serif;
      font-weight: lighter;
      font-size: ${fontSize.extraBig};
      color: ${color.primaryColor};
    }
    p {
      width: 80%;
      line-height: 1.8rem;
      margin: 20px 0 30px;
      color: ${color.darkGrayColor};

      @media (max-width: 800px) {
        width: 100%;
        margin: 20px 0 0 0;
      }
    }
    @media (max-width: 800px) {
      width: 100%;
      margin: 40px 0 0 0;
    }
  }

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: start;
    padding: 100px 7%;
  }
`

export const Button = styled(Link)`
  display: flex;
  align-items: center;
  width: fit-content;
  text-decoration: none;
  color: ${color.primaryColor};
  svg {
    margin-left: 10px;
  }

  @media (max-width: 800px) {
    margin: 20px 0 0 0;
  }
`