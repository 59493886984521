export const data = {
  title: 'O que faço',
  subtitle: 'Processos criativos manuais',
  subtitle2: 'Desenvolvimento Humano e Aprendizagem',

  artesManuais: {
    theme: 'Artes manuais',
    description: 'Toda expressão manual nos mostra como somos, como percebemos o fora e o dentro, as imagens que habitam em nós, nosso tônus, nossa estrutura. Costurar, bordar, tricotar, crochetar, fiar, tecer, amarrar,  pintar, desenhar, recortar, dançar, contar histórias, encenar as histórias,  entre tantas outras formas de se expressar com arte, todas são caminho para autocuidado, autoconhecimento, bem estar e processo terapêutico para o equilíbrio da saúde. A confecção de um ',
    readMore: 'boneco de pano, além de exercer um papel importante na formação da criança quando vivenciada em seu brincar; no adulto, o seu fazer traz uma vivência terapêutica de como conhecer a estrutura e formação humana, seu desenvolvimento e constituição. Com este conhecimento e prazer em fazer estes pequenos seres, o Atelier Semente vem como um portal para conhecermos estes bonecos e um trabalho que visa um processo de produção natural, com consciência, informação e arte na criação de cada peça, cada trabalho. As artes manuais são parte deste processo artístico-terapêutico no caminho da auto-educação e conhecimento de si, na regulação do ritmo interno individual e como papel fundamental na vida social humana. O trabalho com fios e tecidos, suas tramas e amarras, na textura e temperatura, suas cores e tons, chegamos a nossas almas com mais sentido e acolhimento. Sabendo que fazendo podemos nos construir e fortalecer o que nos impulsiona a viver a vida com entusiasmo.',
    img: 'imgWork',
  },
  atendimento: {
    theme: 'Arte educação terapêutica',
    description: 'A Avaliação e Intervenção do  Desenvolvimento Infantil com instrumentos específicos e  métodos Psicopedagógicos, um campo de ação e conhecimento interdisciplinar em Educação e Saúde. Com instrumentos e procedimentos próprios , o processo se dá em sessões previamente estabelecidas com cada família, com escuta e acolhimento em cada caso e indivíduo. A Intervenção no Desenvolvimento Infantil ',
    readMore: 'se dá através através de Oficinas Criativas promovendo estimulação cognitiva, motora e socioemocionais. A Arte Educação Terapêutica tem aplicabilidade em todos os contextos educacionais, sejam escolares, familiares, recreativos e terapêuticos, tendo sempre um efeito profilático e terapêutico. São indicados para casos de dificuldades escolares, comportamentais, emocionais e relacionais, em  trabalho interdisciplinar com Psicólogo, Fonoaudiólogo, Psicomotricista, Pediatra, Neuropediatra, tanto quanto com a Escola e Família.',
  },
  arterapia: {
    theme: 'Arteterapia',
    description: 'A Arteterapia insere-se dentro de um contexto de exploração criativa e valorização do sensível, viabilizado por meio da utilização dos recursos artísticos expressivos. Desta forma, a interação inata entre o sujeito e o mundo dos fenômenos é descoberta (ou resgatada) e integrada por meio do ato criativo e do produto da criação no contexto propício ao desenvolvimento do sujeito e dos acontecimentos, ou seja, no contexto arteterapêutico. Os atendimentos arteterapêuticos ',
    readMore: 'são para crianças, jovens e adultos que buscam um processo terapêutico complementar o tratamento psicoterapêutico e/ou de processos convalescentes, como também apoio ao desenvolvimento humano em fase específica. São sessões individuais ou em grupo ( com tema específico) uma vez na semana em ambiente clínico. Agende um horário para saber mais. ',
  }
}