import React from 'react'
import { useInView } from 'react-intersection-observer';
// Style
import { Container } from './style'
import ImgProp1 from '../../../images/ImgProp1.png'
import ImgProp2 from '../../../images/ImgProp2.png'
// Data
import { data } from '../../../data/dataProposta'



const Proposta = () => {
  const [ ref, ElementEf, entry ] = useInView({
    maginRoot: 100,
    triggerOnce: true,
  })

  return (
    <Container ElementEf={ElementEf}>
      <div className='prop'>
        <h2>{data.title}</h2>
        <div className='images'>
          <div className='first-image'>
            <div ref={ref} className={ ElementEf ? 'maskAnimator active' : 'maskAnimator'}/>
            <img src={ImgProp1} alt=''/>
          </div>
          <div className='second-image'>
            <div className={ ElementEf ? 'maskAnimator2 active2' : 'maskAnimator2'}/>
            <img src={ImgProp2} alt=''/>
          </div>
        </div>
      </div>

      <div className='texts'>
        <h3>{data.subTitle}</h3>
        <p>{data.text}</p>
      </div>
    </Container>
  )
}

export default Proposta