import React from 'react'
// Style
import { Container, ContainerInvest } from './style'
import { TbHeartHandshake } from 'react-icons/tb'
import { FaHandHoldingHeart } from 'react-icons/Fa'
import Fade from 'react-reveal/Fade'
// Data
import { data } from '../../../data/dataBeneficios'

const styleText = {
  fontFamily: 'Marta, san-serif',
  fontSize: '3rem',
  color: '#fb5607',
  maxWidth: 1000,
  textAlign: 'center',
  marginTop: 50,
}
const styleLine = {
  width: '100%',
  height: 1,
  background: '#9e0059',
}

const Beneficios = () => {
  return (
    <>
      <Container>
        <Fade bottom>
          <TbHeartHandshake style={{ fontSize: 40, color: '#9e0059' }}/>
          <p style={styleText}>{data.text}</p>
        </Fade>
      </Container>

      <ContainerInvest>
        <div className='content'>
          <Fade left>
            <div className='image'/>
          </Fade>

          <Fade right>
            <div className='info'>
              <div className='header-info'>
                <div style={styleLine}/>
                <FaHandHoldingHeart
                  className='svg'
                  />
                <div style={styleLine}/>
              </div>
              <h2>{data.inv.title}</h2>
              <p className='p-1'>{data.inv.text}</p>
              <p className='p-2'>{data.inv.text2}</p>

              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 100 }}>
                <div style={{width: '30%', height: 1, background: '#9e0059', }}/>
                <p className='p-3'>{data.inv.detail}</p> 
              </div>
            </div>
          </Fade>
        </div>
      </ContainerInvest>
    </>
  )
}
export default Beneficios