import React, { useState } from 'react'
// Data
import { data } from '../../../data/dataTrabalho'
// style
import { Container } from './style'
import ImgWork from '../../../images/ImgWork.png'
import ImgWork2 from '../../../images/ImgWork2.png'
import ImgWork3 from '../../../images/ImgWork3.png'
// Components
import { CardWork } from '../../../components'


const Trabalho = () => {

  const [arte, setArte] = useState(true)
  const [atend, setAtend] = useState(false)
  const [terapia, setTerapia] = useState(false)

  function handleArte() {
    setArte(true)
    setAtend(false)
    setTerapia(false)
  }
  function handleAtend() {
    setAtend(true)
    setArte(false)
    setTerapia(false)
  }
  function handleTerapia() {
    setTerapia(true)
    setAtend(false)
    setArte(false)
  }

  return (
    <>
      <a id='oquefaço'/>
      <Container arte={arte} atend={atend} terapia={terapia}>
        <h2>{data.title}</h2>
        <p className='p'>{data.subtitle}<br/>{data.subtitle2}</p>
        <div className='buttons'>
          <button onClick={handleArte} className='arte'>{data.artesManuais.theme}</button>
          <button onClick={handleAtend} className='atend'>{data.atendimento.theme}</button>
          <button onClick={handleTerapia} className='terapia'>{data.arterapia.theme}</button>
        </div>
        { arte 
          ? <CardWork
              img={ImgWork}
              theme={data.artesManuais.theme}
              description={data.artesManuais.description}
              readMore={data.artesManuais.readMore}
            />
          : null
        }
        { atend 
          ? <CardWork
              img={ImgWork2}
              theme={data.atendimento.theme}
              description={data.atendimento.description}
              readMore={data.atendimento.readMore}
            />
          : null
        }
        { terapia 
          ? <CardWork
              img={ImgWork3}
              theme={data.arterapia.theme}
              description={data.arterapia.description}
              readMore={data.arterapia.readMore}
            />
          : null
        }
      </Container>
    </>
  )
}
export default Trabalho