import styled from 'styled-components'
// Data
import { color, fontSize } from '../../../data/dataPatterns'


export const Container = styled.section`
  padding: 100px 13%;
  display: flex;
  flex-direction: row; 

  .prop {
    width: 40%;
    h2{
      position: relative;
      font-family: Marta, serif;
      font-weight: lighter;
      font-size: ${fontSize.extraBig};
      color: ${color.primaryColor};
      &::after {
        content: '';
        position: absolute;
        width: 2px;
        height: 100%;
        background: white;
        right: 0;
      }
    }
    .images{
      width: 100%;
      .first-image{
        position: relative;
        z-index: 100;
        margin-left: auto;
        padding-left: 50px;
        transform: translateY(60px);
        img {
          width: 100%;
          height: 100%;
        }
        .maskAnimator {
          position: absolute;
          background: #ede0d4;
          width: 100%;
          overflow: hidden;
          height: 100%;
        }
        .active {
          animation: Mask 1s ease-in-out 0.5s forwards;
          
        }
        @keyframes Mask {
          to {
            width: 0%;
          }
        }
      }
      .second-image{
        position: relative;
        z-index: 1;
        padding-right: 50px;
        img {
          width: 100%;
          height: 100%;
        }
        .maskAnimator2 {
          position: absolute;
          background: #ede0d4;
          width: 100%;
          height: 100%;
        }
        .active2 {
          animation: Mask2 1s ease-in-out 1.5s forwards;
          
        }
        @keyframes Mask2 {
          to {
            height: 0%;
          }
        }
      }
    }
  }

  .texts{
    margin: 20px 0 0 9%;
    h3{
      font-family: Marta, serif;
      font-weight: lighter;
      font-size: ${fontSize.extraMiddle};
      color: ${color.orangeColor};
      margin-bottom: 30px;
    }
    p{
      color: ${color.darkGrayColor};
      max-width: 400px;
      line-height: 1.5rem;
    }
  }



  @media (max-width: 768px) {
    flex-direction: column;
    padding: 100px 8%;
    .prop {
      width: 100%;
      h2 {
        &::after {
          content: '';
          width: 70%;
          height: 2px;
          left: 0;
          bottom: 0;
        }
      }
    }
    .texts {
      margin: 0;
    }
  }
`