import React from 'react'
// Link
import { Link } from 'gatsby'
// Data
import { data } from '../../../data/dataAbout'
// Static Image
import { StaticImage } from 'gatsby-plugin-image'
//Style
import { Container, Button } from './style'
import { ArrowRight } from '../../../Icons'


const About = () => {
  return (
    <Container>
      <StaticImage
        src='../../../images/imgAbout.png'
        alt='Minha Foto'
        quality='100'
      />
      <div className='infos'>
        <h1>{data.title}</h1>
        <p>{data.text}</p>
        <Button to='/sobre'>
          {data.buttonText}
          <ArrowRight/>
        </Button>
      </div>
    </Container>
  )
}
export default About