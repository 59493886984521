export const data = {
  title: 'Proposta',
  subTitle: 'Desenvolvimento',
  text: 'A proposta do Atelier Semente está embasada no conhecimento do Ser Humano, através da Antroposofia e Pedagogia Waldorf, em  oferecer atendimentos terapêuticos PARA CRIANÇAS, JOVENS, ADULTOS E MATURIDADE através das Arte-Manuais, no campo de atuação Psicopedagógico e Arteterapêutico.',
}

export const words = [
  'Arte',
  'Desenvolvimento humano',
  'Aprendizagem',
  'Bem estar',
  'Saúde mental'
]