import * as React from 'react'
// components
import * as C from '../components'
import FontStyle from '../fonts/fontStyle'
// Sections
import * as S from '../containers/home'
// Image
import Backimage from '../images/imgHero.png'

// Data da seção "Hero"
const data = {
  titleI: 'Atelier',
  titleII: 'Semente',
  text: 'Atelier arteterapêutico e atendimentos psicopedagógicos de desenvolvimento humano e aprendizagem.',
  primaryButton: 'Contato',
}


const IndexPage = () => {
  return (

    // Seções da pagina Home
    <>
      <C.SEO
        title='Atelier Semente | Arteterapia e atendimentos'
        description='Atelier arteterapêutico e atendimentos psicopedagógicos de desenvolvimento humano e aprendizagem em Campinas.'
      />
      <C.Header/>
      <C.Hero 
        title={data.titleI}
        title2={data.titleII}
        text={data.text}
        primaryButton={data.primaryButton}
        secondButton={data.secondButton}
        image={Backimage}
      />
      <S.Beneficios/>
      <S.Proposta/>
      <S.About/>
      <S.Trabalho/>
      <S.Oficinas/>
      <C.Footer/>

      <FontStyle/>
      <C.GlobalStyles />
    </>
  )
}

export default IndexPage
