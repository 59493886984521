import React from 'react'
// Data
import { data } from '../../../data/dataOficinas'
// Style
import { Container, ButtonLink } from './style'
import ImgOficina from '../../../images/imgOficinas.png'
import { Button } from '../../../Icons'

const Oficinas = () => {
  return (
    <Container>
      <div className='infos'>
        <div className='detail'>
          <div className='line'/>
          <span>{data.detail}</span>
        </div>
        <div className='main-texts'>
          <h2>{data.title}</h2>
          <p>{data.text}</p>
          <ButtonLink to='/oficinas'>
            {data.button}
            <Button/>
          </ButtonLink>
        </div>
        <div className='blue-container'/>
      </div>

      <img src={ImgOficina} />
    </Container>
  )
}
export default Oficinas
