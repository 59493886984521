import styled from 'styled-components'
import { color, fontSize } from '../../../data/dataPatterns'
import Image from '../../../images/imgInvestir.png'

export const Container = styled.section`
  padding: 200px 11% 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  @media (max-width: 768px) {
    padding: 100px 7%;
  }
`

export const ContainerInvest = styled.section`
  display: flex;
  justify-content: center;
  padding: 100px 11%;
  background: #F69D71;
  .content {
    display: flex;
    flex-direction: row;
    border-radius: 70px;
    width: fit-content;
    .image{
      position: relative;
      width: 400px;
      background: url(${Image}) no-repeat center bottom;
      background-size: cover;
      border-radius: 50px;
    }
    .info {
      padding: 4rem 6rem;
      .header-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 60px;
        .svg{
          font-size: 140px; 
          color: #9e0059;
          margin: 0 80px;
          height: initial;
        }
      }
      h2{
        font-family: Marta, serif;
        font-size: ${fontSize.extra};
        font-weight: lighter;
        color: ${color.whiteColor};
      }
      .p-1{
        max-width: 500px;
        margin: 40px 0 60px;
        color: ${color.darkGrayColor};
      }
      .p-2{
        max-width: 500px;
        color: ${color.darkGrayColor};
      }
      .p-3{
        font-size: ${fontSize.extraSmall};
        margin-left: 20px;
        color: ${color.primaryColor};
      }
    }
  }


  @media (max-width: 768px) {
    padding: 100px 7%;
    .content {
      flex-direction: column;
      border-radius: 50px;
      width: 100%;
      height: fit-content;
      .image{
        position: relative;
        width: 100%;
        height: 400px;
        background: url(${Image}) no-repeat center bottom;
        background-size: cover;
        border-radius: 50px 50px 0 0;
      }
      .info {
        padding: 4rem 4rem;
        .p-1{line-height: 1.5rem;}
        .p-2{line-height: 1.5rem;}
      }
    }
  }

  @media (max-width: 480px) {
    .content{
      .info {
        padding: 4rem 0;
        .header-info{
          .svg{
            font-size: 100px;
            padding: 0;
            margin: 0 30px;
          }
        }
        .p-1{
          margin: 30px 0 40px;
        }
      }
    }
  }
`