import styled from 'styled-components'
import { color, fontSize } from '../../../data/dataPatterns'


export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 13%;
  background: ${color.whiteColor};
  h2 {
    font-family: Marta, serif;
    font-weight: lighter;
    font-size: ${fontSize.extraBig};
    color: ${color.primaryColor};
    margin-bottom: 1.5rem;
  }
  .p {
    font-size: 1rem;
    line-height: 1.5rem;
    color: ${color.darkGrayColor};
    max-width: 500px;
    text-align: center;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 60px;
    button {
      width: 160px;
      height: 60px;
      padding: 0 15px;
      background: none;
      margin-right: 20px;
      font-family: unset;
      font-weight: bolder;
      border: 2px solid;
      border-radius: 100px;
      cursor: pointer;
      &:last-child {
        margin-left: 0;
      }
    }
    .arte {
      color: ${({arte}) => (arte ? 'white' : '#fb5607')};
      background: ${({arte}) => (arte ? 'rgba(102, 155, 188, 0.7)' : 'transparent')};
      border-color: ${({arte}) => (arte ? '#669bbc' : '#fb5607')};

    }
    .atend {
      color: ${({atend}) => (atend ? 'white' : '#fb5607')};
      background: ${({atend}) => (atend ? 'rgba(102, 155, 188, 0.7)' : 'transparent')};
      border-color: ${({atend}) => (atend ? '#669bbc' : '#fb5607')};
    }
    .terapia {
      color: ${({terapia}) => (terapia ? 'white' : '#fb5607')};
      background: ${({terapia}) => (terapia ? 'rgba(102, 155, 188, 0.7)' : 'transparent')};
      border-color: ${({terapia}) => (terapia ? '#669bbc' : '#fb5607')};
    }
  }


  @media (max-width: 768px) {
    padding: 100px 8%;
    align-items: center;
  }
  @media (max-width: 480px) {
    padding: 100px 7%;
    .buttons {
      margin-top: 20px;
      button {
        width: 30vw;
        margin-right: 5px;
        border-radius: 10px;
        margin-top: 20px;
        &:last-child{
          margin-right: 0px;
        }
      }
    }
  }
`