export const data = {
  text: 'As artes manuais estão presentes no desenvolvimento humano desde os primórdios. O que diferencia o ser humano dos outros seres vivos na natureza é sua capacidade de criar manualmente e compartilhar suas criações no social.',

  inv: {
    title: 'Porque investir?',
    text: 'Quando investimos na aprendizagem das artes manuais criativas desde a infância até a idade madura, favorecemos o desenvolvimento de habilidades  cognitivas, emocionais, criativas e motoras, necessárias para um desenvolvimento humano íntegro e saudável.',
    text2: 'Com processos criativos e manuais através das artes têxteis, com abordagem artística,  psicopedagógica e arteterapêutica, todos podem melhorar sua qualidade de vida social, cognitiva e emocional.',
    detail: 'Desenvolvimento humano',
  }
}






