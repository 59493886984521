import styled from 'styled-components'
import { color, fontSize } from '../../../data/dataPatterns'
// Link
import { Link } from 'gatsby'



export const Container = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .infos {
    position: relative;
    width: 100%;
    padding: 100px 0;
    .detail {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 40px;
      padding-left: 20%;
      .line {
        width: 120px;
        height: 2px;
        background: ${color.grayColor};
        border-radius: 100px;
      }
      span {
        color: ${color.grayColor};
        font-size: 0.875rem;
        margin-left: 15px;
      }
    }
    .main-texts {
      padding-left: 20%;
      h2 {
        font-family: Marta, serif;
        font-weight: lighter;
        font-size: ${fontSize.extraBig};
        color: ${color.primaryColor};
      }
      p {
        color: ${color.darkGrayColor};
        max-width: 550px;
        margin: 40px 10px 40px 0;
        line-height: 1.7rem;
      }
    }
    .blue-container {
      position: absolute;
      width: 100%;
      height: 120px;
      background: ${color.blueColor};
      bottom: 0;
    }
  }
  .image {}

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    .infos{
      .detail{
        padding-left: 7%;
      }
      .main-texts {
        padding-left: 7%;
        margin-bottom: 120px;
      }
      .blue-container{
        height: 100px;
        bottom: 0;
      }
    }
  }
  @media (max-width: 480px) {
    img {
      max-width: 480px;
    }
  }
`

export const ButtonLink = styled(Link)`
  font-size: 1.3rem;
  text-decoration: none;
  color: ${color.primaryColor};
  display: flex;
  flex-direction: row;
  align-items: center;
  svg{
    margin-left: 15px;
  }
`